var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h2", [_vm._v(_vm._s(_vm.title))]),
      _c(
        "app-form",
        {
          attrs: {
            id: "employee-role-form",
            submitAction: _vm.edit,
            submitSuccessAction: _vm.editSuccess,
            validationOptions: _vm.validationOptions
          }
        },
        [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-textbox", {
                attrs: { id: "name", placeholder: _vm.$t("shared.name") },
                model: {
                  value: _vm.employeeRole.name,
                  callback: function($$v) {
                    _vm.$set(_vm.employeeRole, "name", $$v)
                  },
                  expression: "employeeRole.name"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-textbox", {
                attrs: {
                  id: "description",
                  placeholder: _vm.$t("shared.description")
                },
                model: {
                  value: _vm.employeeRole.description,
                  callback: function($$v) {
                    _vm.$set(_vm.employeeRole, "description", $$v)
                  },
                  expression: "employeeRole.description"
                }
              })
            ],
            1
          ),
          _c("app-form-buttons", {
            attrs: {
              addSubmitButton: true,
              submitButtonText: "shared.edit",
              addCancelButton: true,
              cancelAction: _vm.cancel
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }